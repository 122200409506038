<template>
  <div>
    <!-- Main Sidebar Container -->
    <aside class="menu-custom main-sidebar sidebar-primary elevation-4">
      <!-- Brand Logo -->
      <a href="/admin/integrations" class="brand-link mb-5">
        <img
            class="image-logo"
            src="@/assets/images-logo/tallos-logo-name.png"
            alt="Tallos Logo"
            width="220"
            height="38"
        />
      </a>

      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar Menu -->
        
        <nav class="mt-2">
          <ul
              class="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
          >

          <li class="nav-item has-treeview mt-3">
              <router-link to="/admin/opportunities" class="nav-link" v-if="['seller'].includes(perfilUser)">
                <i class="nav-icon far fa-clipboard-list font-color-custom"></i>
                <p class="font-color-custom">
                  Oportunidades
                  <i class="right"></i>
                </p>
              </router-link>
            </li>

            <li class="nav-item has-treeview" v-if="['admin', 'seller'].includes(perfilUser)">
              <router-link to="/admin/contracts" class="nav-link">
                <i class="nav-icon fa fa-file-contract font-color-custom"></i>
                <p class="font-color-custom">
                  Contratos
                  <i class="right"></i>
                </p>
              </router-link>
            </li>


            <li class="nav-item has-treeview" v-if="['admin', 'support', 'finance'].includes(perfilUser)">
              <router-link to="/admin/integrations" class="nav-link">
                <i class="nav-icon fa fa-project-diagram font-color-custom"></i>
                <p class="font-color-custom">
                  Integrações
                  <i class="right"></i>
                </p>
              </router-link>
            </li>

            <li class="nav-item has-treeview" v-if="['admin', 'support', 'finance'].includes(perfilUser)">
              <router-link to="/admin/companies" class="nav-link">
                <i class="nav-icon fa fa-building font-color-custom"></i>
                <p class="font-color-custom">
                  Empresas
                  <i class="right"></i>
                </p>
              </router-link>
            </li>


            <li class="nav-item has-treeview" v-if="(perfilUser === 'admin')">
              <router-link to="/admin/users" class="nav-link">
                <i class="nav-icon fa fa-users font-color-custom"></i>
                <p class="font-color-custom">
                  Usuários
                  <i class="right"></i>
                </p>
              </router-link>
            </li>

            <li class="nav-item has-treeview" v-if="(perfilUser === 'admin' || perfilUser === 'support')">
              <div class="nav-link">
                <div data-bs-toggle="collapse" data-bs-target="#collapseExample" role="button" aria-controls="collapseExample" aria-expanded="false">
                  <i class="nav-icon fas fa-border-all font-color-custom"></i>
                  <p class="font-color-custom">
                    Notificações
                  </p>
                </div>
                <div class="collapse" id="collapseExample">
                  <div class="d-flex flex-column">
                    <router-link to="/admin/notifications/pop-up" class="nav-link">
                      <i class="nav-icon far fa-bell font-color-custom"></i>
                      <p class="font-color-custom">
                        Pop-Up
                      </p>
                    </router-link>
                  </div>
                  <div class="d-flex flex-column">
                    <router-link to="/admin/notifications/nps" class="nav-link">
                      <i class="nav-icon far fa-star font-color-custom"></i>
                      <p class="font-color-custom">
                        Nps
                      </p>
                    </router-link>
                  </div>
                  <div class="d-flex flex-column">
                    <router-link to="/admin/kanban" class="nav-link">
                      <i class="nav-icon fab fa-kickstarter font-color-custom"></i>
                      <p class="font-color-custom">
                        Kanban
                      </p>
                    </router-link>
                  </div>
                  <div class="d-flex flex-column">
                    <router-link to="/admin/news" class="nav-link">
                      <i class="nav-icon fas fa-exclamation-circle font-color-custom"></i>
                      <p class="font-color-custom">
                        Novidades
                      </p>
                    </router-link>
                  </div>
                </div>
              </div>
            </li>
            <!-- TODO Leads (?) -->
            <!-- <li class="nav-item has-treeview" v-if="(perfilUser === 'admin' || perfilUser === 'seller')">
              <router-link to="/admin/leads" class="nav-link">
                <i class="nav-icon fa fa-comments-dollar font-color-custom"></i>
                <p class="font-color-custom">
                  Leads
                  <i class="right"></i>
                </p>
              </router-link>
            </li> -->

            <!-- TODO Whatsapp Api -->
            <!-- <li class="nav-item has-treeview" v-if="(perfilUser === 'admin' || perfilUser === 'support')">
              <router-link to="/admin/whatsappApi" class="nav-link">
                <i class="nav-icon fab fa-whatsapp font-color-custom"></i>
                <p class="font-color-custom">
                  Whatsapp API
                  <i class="right"></i>
                </p>
              </router-link>
            </li> -->

            <!-- TODO Whatsapp Planos -->
            <!-- <li @click="triggerPlans()" class="nav-item has-treeview"
                v-if="(perfilUser === 'admin' || perfilUser === 'finance')">
              <router-link to="/admin/plans" class="nav-link">
                <i class="nav-icon fa fa-trophy font-color-custom" aria-hidden="true"></i>
                <p class="font-color-custom">
                  Planos
                  <i class="right"></i>
                </p>
              </router-link>
            </li> -->
  
            <li class="nav-item has-treeview">
              <router-link to="/admin/academy" class="nav-link" v-if="(perfilUser === 'admin' || perfilUser === 'support' || perfilUser === 'finance')">
                <i class="nav-icon fas fa-graduation-cap font-color-custom"></i>
                <p class="font-color-custom">
                  Academy
                  <i class="right"></i>
                </p>
              </router-link>
            </li>

            <li class="nav-item has-treeview" v-if="(perfilUser === 'admin' || perfilUser === 'support' || perfilUser === 'finance')">
              <div class="nav-link">
                <div data-bs-toggle="collapse" data-bs-target="#collapseNormatization" role="button" aria-controls="collapseNormatization" aria-expanded="false">
                  <i class="nav-icon fas fa-tools font-color-custom"></i>
                  <p class="font-color-custom">
                    Normalizações
                  </p>
                </div>
                <div class="collapse" id="collapseNormatization">
                  <div class="d-flex flex-column">
                    <router-link to="/admin/normalizations/contacts" class="nav-link">
                      <i class="nav-icon fas fa-users font-color-custom"></i>
                      <p class="font-color-custom">
                        Contatos
                        <i class="right"></i>
                      </p>
                    </router-link>
                  </div>
                  <div class="d-flex flex-column">
                    <router-link to="/admin/normalizations/gupshup-api-key" class="nav-link">
                      <i class="nav-icon fas fa-plug font-color-custom"></i>
                      <p class="font-color-custom">
                        Gupshup ApiKey
                        <i class="right"></i>
                      </p>
                    </router-link>
                  </div>
                </div>
              </div>
            </li>

            <li class="nav-item has-treeview" v-if="(perfilUser === 'admin')">
              <router-link to="/admin/webhooks" class="nav-link">
                <i class="nav-icon fas fa-globe font-color-custom"></i>
                <p class="font-color-custom">
                  Webhooks
                  <i class="right"></i>
                </p>
              </router-link>
            </li>

            <li class="nav-item has-treeview" v-if="(perfilUser === 'admin')">
              <router-link to="/admin/logs" class="nav-link">
                <!-- <i class="fab fa-blogger"></i> -->
                <i class="nav-icon far fa-clipboard-list font-color-custom"></i>
                <p class="font-color-custom">
                  Logs
                  <i class="right"></i>
                </p>
              </router-link>
            </li>

            <li class="nav-item has-treeview" v-if="(perfilUser === 'admin')">
              <router-link to="/admin/settings" class="nav-link">
                <!-- <i class="fab fa-blogger"></i> -->
                <!-- <i class="nav-icon far fa-clipboard-list font-color-custom"></i> -->
                <font-awesome-icon icon="fa-solid fa-gear" class="font-color-custom nav-icon" />
                <p class="font-color-custom">
                  Configurações Gerais
                  <i class="right"></i>
                </p>
              </router-link>
            </li>

            <li class="nav-item has-treeview" v-if="(perfilUser === 'admin')">
              <router-link to="/admin/trial" class="nav-link">
                <!-- <i class="fab fa-blogger"></i> -->
                <!-- <i class="nav-icon far fa-clipboard-list font-color-custom"></i> -->
                <i class="font-color-custom nav-icon fas fa-flask" />
                <p class="font-color-custom">
                  Solicitações de Trial
                  <i class="right"></i>
                </p>
              </router-link>
            </li>
            <li class="nav-item has-treeview" v-if="['admin', 'support', 'finance'].includes(perfilUser)">
              <router-link to="/admin/reports" class="nav-link">
                <i class="nav-icon fa fa-file-excel font-color-custom"></i>
                <p class="font-color-custom">
                  Planilha Admin
                  <i class="right"></i>
                </p>
              </router-link>
            </li>
            <!-- TODO Solicitações Financeiras -->
            <!-- <li class="nav-item has-treeview" v-if="(perfilUser === 'admin' || perfilUser === 'finance')">
              <router-link to="/admin/financialRequests" class="nav-link">
                <i class="nav-icon fa fa-cubes font-color-custom"></i>
                <p class="font-color-custom">
                  Solicitações
                  <i class="right"></i>
                </p>
              </router-link>
            </li> -->

            <!-- <li class="nav-item has-treeview" >
              <router-link to="/admin/apresentaion" class="nav-link" >
                <i class="nav-icon fa fa-pager font-color-custom"></i>
                <p class="font-color-custom">
                  Apresentações
                  <i class="right"></i>
                </p>
              </router-link>
            </li> -->

          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>
  </div>
</template>

<script>
import {bus} from "./../../../../../main"

export default {

  methods: {
    triggerPlans() {
      bus.$emit('plans-triggered')
    }
  },

  computed: {
    nameAdmin() {
      if (this.$store && this.$store.state && this.$store.state.admin && this.$store.state.admin.name) {
        return this.$store.state.admin.name;
      } else {
        return "Tallos Admin";
      }
    },

    perfilUser() {
      if (this.$store && this.$store.state && this.$store.state.admin && this.$store.state.admin.perfil) {
        return this.$store.state.admin.perfil;
      } else {
        return "Tallos Admin";
      }
    }
  },
};
</script>

<style scoped>
.menu-custom {
  background-color: rgb(24, 24, 24);
}

.nav-pills .nav-link.active {
  background-color: rgba(80, 80, 80, 0.6) !important;
}

.sub-itens {
  margin-top: 10px;
  margin-left: 15px;
}

.font-color-custom,
.font-color-custom:hover {
  color: #fff;
  font-weight: 600 !important;
}

.link-disabled {
  cursor: not-allowed;
}

.image-logo {
  margin: 5px 15px 0px 7px !important;
}
</style>